<template>
  <!-- Inicio Formulario -->
  <!-- XS to SM Screen -->
  <div id="ultimasPreguntas" class="container-fluid p-0 d-block d-sm-block d-md-none d-lg-none d-xl-none" :style="checkMargins()" >
    <div class="row justify-content-around align-items-center p-4 ">
      <div class="col-7">
          <div class="row justify-content-center">
            <ul class="tick-list" style="list-style-type: none;">
              <p class="image-text">Hasta <text class="title-orange">6000€</text> <br class="hidden-md-and-down"> en 24h con tu <br class="hidden-md-and-down"> coche como aval</p>
              <li>
                <el class="tick">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                  </svg>
                </el>
                El coche siempre es suyo
              </li>
              <li>
                <el class="tick">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                  </svg>
                </el>
                100% online
              </li>
              <li>
                <el class="tick">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                  </svg>
                </el>
                Tu préstamo en el mismo día
              </li>
            </ul>
          </div>
      </div>
      <div class="col-5">
        <div class="row justify-content-center">
          <img src="@/assets/img/promo50mobile.svg" class="img-fluid bg-img" alt="Responsive image" />
        </div>
      </div>
    </div>
    <div class="row justify-content-center align-items-center franja-azul mb-3">
      <div class="col-12">
        <h1 class="title-2 m-0">Necesitamos confirmar 3 datos</h1>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="container-lg">
          <Step1API />
          <Step2API />
          <Step3API />
        </div>
        <cardsFormulario  />
      </div>
    </div>
  </div>

  <!-- MD to LG Screen -->
  <div class="container-fluid p-0 d-none d-sm-none d-md-block d-lg-block d-xl-none top">
    <div class="row justify-content-around align-items-center p-4 ">
      <div class="col-7">
          <div class="row justify-content-center">
            <ul class="tick-list" style="list-style-type: none;">
              <p class="image-text">Hasta <text class="title-orange">6000€</text> <br class="hidden-md-and-down"> en 24h con tu <br class="hidden-md-and-down"> coche como aval</p>
              <li>
                <el class="tick">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                  </svg>
                </el>
                El coche siempre es suyo
              </li>
              <li>
                <el class="tick">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                  </svg>
                </el>
                100% online
              </li>
              <li>
                <el class="tick">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                  </svg>
                </el>
                Tu préstamo en el mismo día
              </li>
            </ul>
          </div>
      </div>
      <div class="col-5">
        <div class="row justify-content-center">
          <img src="@/assets/img/promo_50.svg" width="320" height="320" alt="Responsive image" />
        </div>
      </div>
    </div>

    <div class="row franja-azul">
      <div class="col">
        <h1 class="title-2 mt-3 pt-1" >Necesitamos confirmar 3 datos</h1>
      </div>
    </div>
    <div class="container-lg">
      <Step1API v-if="stepsAPI == 1" />
      <Step2API v-if="stepsAPI == 2" />
      <Step3API v-if="stepsAPI == 3" />
      
    </div>
    <cardsFormulario v-if="stepsAPI == 3" />

    <!--PelotitasPasosApi /-->
    <br />
  </div>
  <!-- XL to XXL Screen-->
  <div class="container-fluid p-0 d-none d-sm-none d-md-none d-lg-none d-xl-block" :style="checkMargins()">
    <div class="row justify-content-center align-items-center">
      <div class="col-5">
        <div class="row justify-content-center">
          <div class="col-xl-12">
            <div class="row justify-content-center">
              <div class="">
                <h1 class="title title-1 text-left" >
                  Hasta <text class="title-orange">6000€</text> en 24h con tu coche como aval
                </h1>
                <ul v-show="this.afiliado == '04'" class="tick-list" style="padding-left: 0; list-style-type: none">
                  <li class="tick">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                      <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                    </svg>
                    Sin cambiar la titularidad del coche
                  </li>
                  <li class="tick">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                      <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                    </svg>
                    100% online
                  </li>
                  <li class="tick">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                      <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                    </svg>
                    Tu préstamo en el mismo día
                  </li>
                </ul>
              </div>
            </div>
            <div class="row justify-content-center" v-show="this.afiliado != '04'">
                <div class="" >
                  <img src="@/assets/img/promo_50.svg" alt="Promo50"  class="img-fluid" >
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="row justify-content-center">
          <div>
            <img src="@/assets/img/2sinfondo.svg" alt="personas" width="700" height="460" class="" />
          </div>
        </div>
        
      </div>
    </div>
    <div class="row justify-content-center franja-azul">
      <div class="col-12">
        <h1 class="title-2 mt-4" v-if="widthScreen >= 1200">Necesitamos confirmar 3 datos</h1>
      </div>
    </div>
    <div class="container-lg">
      <Step1API v-if="stepsAPI == 1" />
      <Step2API v-if="stepsAPI == 2" />
      <Step3API v-if="stepsAPI == 3" />
    </div>
    <PelotitasPasosApi />
    <cardsFormulario v-if="stepsAPI == 3" />
    <br />
  </div>
</template>

<script>
import store from "@/store";
import Step1API from "../NuevoFormulario/Step1API.vue";
import Step2API from "../NuevoFormulario/Step2API.vue";
import Step3API from "../NuevoFormulario/Step3API.vue";
import PelotitasPasosApi from "../NuevoFormulario/PelotitasPasosAPI.vue";
import { mapState } from "vuex";
import cardsFormulario from "../NuevoFormulario/cardsFormulario.vue";

export default {
  name: "StepUltimasPreguntas",
  data() {
    return {
      publicPath: process.env.BASE_URL,
      widthScreen: window.innerWidth, // Utilizamos esta variable para indicar dinamicamente que elementos cargamos de esta pantalla
    };
  },
  components: {
    Step1API,
    Step2API,
    Step3API,
    PelotitasPasosApi,
    cardsFormulario,
  },
  store,
  computed: mapState({
    step: (state) => state.formulario.step,
    data_matricula: (state) => state.formulario.data_matricula,
    title: (state) => state.section1.title,
    formulario: (state) => state.formulario,
    stepsAPI: (state) => state.stepsAPI,
    afiliado: (state) => state.afiliado,
  }),
  methods: {
    getYear(fecha) {
      let date = new Date(fecha);
      return date.getFullYear();
    },
    checkMargins() {
      if (this.widthScreen <= 440) {
        return 'margin-top: 130px;'
      }else if (this.widthScreen > 440 && this.widthScreen <= 470) {
        return 'margin-top: 140px;'
      }else if (this.widthScreen > 470 && this.widthScreen <= 499) {
        return 'margin-top: 150px;'
      }else if (this.widthScreen > 499 && this.widthScreen <= 575) {
        return 'margin-top: 160px;'
      }else if (this.widthScreen > 575 && this.widthScreen <= 768) {
        return 'margin-top: 130px;'
      }else if (this.widthScreen >= 1200 && this.widthScreen <= 1880) {
        return 'margin-top: 150px;'
      }else if (this.widthScreen > 1880) {
        return 'margin-top: 155px;'
      }
    },
    onResize() {
      this.widthScreen = window.innerWidth; //Width Setter
      this.checkMargins();

    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize); // En cada tick cogemos el tamaño de la pantalla y lo actualizamos de forma dinamica
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  created: function () {
    store.commit("setStepAPI", 1);
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables.scss";
.card {
  background-color: white;
  border: 2px solid rgba(196, 196, 196, 0.4);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  &:hover {
    background-color: #004680;
    color: white;
    border: 2px solid rgba(196, 196, 196, 0.4);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    cursor: pointer;
  }
  &:active {
    background-color: #004680;
    color: white;
    border: 2px solid rgba(196, 196, 196, 0.4);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
  }
}
.active {
  background-color: #004680;
  color: white;
  border: 2px solid rgba(196, 196, 196, 0.4);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}
.title {
  width: 100%;
  font-family: "Montserrat";
  font-display: swap;
  font-style: normal;
  text-align: center;
  color: $blue;
}
.title-orange {
  width: 100%;
  font-family: "Montserrat-Bold";
  font-display: swap;
  font-style: normal;
  // line-height: auto;
  text-align: center;
  color: $orange;
}
.image-text {
  color: $blue;
  font-family: "Montserrat-Bold";
}
.image-text b {
  font-weight: bolder;
  font-family: "Montserrat-Bold";
}
.tick {
  color: $blue;
  font-size: 22px;
}
.title-1 {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-weight: 600;
}
.title-2 {
  color: $blue;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  text-align: center;
  line-height: 20px;
}
.card-text {
  font-family: "Montserrat-bold";
  color: $blue;
}
.franja-azul {
  background-color: $blue-3;
  height: 70px;
}

// Resposive para pantallas a partir de tamaño medio a pequeño

@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

@include media-breakpoint-only(xs) {
  .title-2 {
    font-size: 16px;
  }
  .title-1 {
    font-size: 20px !important;
    color: white;
  }

  .image-text {
    font-size: 19px;
  }
  .tick-list {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #004680;
    line-height: 25px;
    margin-top: -15%;
    margin-left: -5.5vh;
  }
  .franja-azul {
    background-color: $blue-3;
    height: 47px;
  }
  .title-orange {
    font-size: 20px;
  }
}
@include media-breakpoint-only(sm) {

  .title-2 {
    font-size: 16px;
  }
  .tick-list {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #004680;
  }
  .title-1 {
    font-size: 22px !important;
    color: white;
  }
  .image-text {
    font-size: 22px;
    line-height: 28px;
  }


}

@include media-breakpoint-only(md) {
  .tick-list {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    color: #004680;
    line-height: 40px;
  }

  .top {
    margin-top: 15% !important;
  }
  .image-text {
    font-size: 2.25rem;
    line-height: normal;
  }
  .card-columns {
    column-count: 2;
  }
  .card {
    border: 1px solid rgba(196, 196, 196, 0.4);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    width: 326px;
    height: 88px;
    color: $blue;
    font-weight: bold;
  }
  .title-1 {
    font-size: 30px !important;
    letter-spacing: 0.1cap !important;
    // text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .title-2 {
    color: $blue;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    text-align: center;
    font-size: 24px;
  }
}

@include media-breakpoint-only(lg) {
  .tick {
    font-size: 2rem;
  }
  .tick-list {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    color: #004680;
    line-height: 52px;
  }
  .image-text {
    font-size: 2.75rem;
    line-height: normal;
  }
  .top {
    margin-top: 14% !important;
  }
  .title .title-1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 65px;
    line-height: 79px;
    color: #004680;
    text-shadow: 0px 4px 4px;
  }
  fieldset {
    margin: 5vh 15% 5vh 15%;
    padding: 2%;
    border: 1px #b3dcff solid;
  }
  .bg-img {
    // margin-top: 60px;
    background-image: linear-gradient(to right, white, transparent 50%), url("../../assets/img/home_3_ipad.webp");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 600px;
    min-width: auto;
    margin: 0;
    max-width: 100%;
    max-height: 100%;
    height: 100%;
  }
  .card-columns {
    column-count: 2;
    max-width: 85%;
  }
  .card {
    min-width: 310px !important;
    width: 310px !important;
    min-height: 103px;
    height: 103px;
    border: 1px solid rgba(196, 196, 196, 0.4);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    color: $blue;
    font-weight: bold;
  }
  .title-1 {
    font-size: 42px !important;
    // text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .title-2 {
    color: $blue;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    text-align: center;
    line-height: 20px;
  }
}

@include media-breakpoint-only(xl) {
  .tick-list {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    color: #004680;
    line-height: 50px;
  }

  .top {
    margin-top: 7% !important;
  }
  .card-columns {
    column-count: 2;
  }
  .card {
    border: 1px solid rgba(196, 196, 196, 0.4);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    width: 326px;
    height: 88px;
    color: $blue;
    font-weight: bold;
  }
  .title-1 {
    font-size: 52px !important;
    // text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .title-2 {
    color: $blue;
    font-family: "Montserrat";
    font-style: normal;
    text-align: center;
    font-weight: 600;
    font-size: 26px;
    line-height: 20px;
  }
}

@include media-breakpoint-between(xl, xxl) {
  .card-columns {
    column-count: 2;
  }
  .card {
    border: 1px solid rgba(196, 196, 196, 0.4);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    width: 326px;
    height: 88px;
    color: $blue;
    font-weight: bold;
  }
  .title-1 {
    font-size: 2.75rem !important;
    line-height: normal;
    // text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .title-2 {
    color: $blue;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    text-align: center;
    font-size: 26px;
    line-height: 20px;
  }
}
</style>
